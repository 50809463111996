import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accessibility-statement-tilgjengelighetserklæring"
    }}>{`Accessibility Statement (Tilgjengelighetserklæring)`}</h1>
    <p>{`You will find the current WCAG compliance status of our widgets through the following links:`}</p>
    <p><a parentName="p" {...{
        "href": "https://uustatus.no/nb/erklaringer/publisert/276dd0b3-ecba-45b7-b584-0e7d2485791e"
      }}>{`Tilgjengelighetserklæring (Bokmål)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://uustatus.no/nn/erklaringer/publisert/276dd0b3-ecba-45b7-b584-0e7d2485791e"
      }}>{`Tilgjengelighetserklæring (Nynorsk)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      